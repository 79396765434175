@import '../../../vendor/sb-admin-2/vendor/bootstrap/scss/functions';
@import '../../../vendor/sb-admin-2/vendor/bootstrap/scss/variables';

.distribution {
  font-size: $font-size-sm;
  font-weight: 500;
  padding-bottom: 2rem;

  &.view-cards {

    table {
      font-size: 16px;

      td,
      th {
        padding: .25rem .25rem .25rem .5rem;
      }

      .assortment-row-btn,
      .client-row-btn {
        margin: .5rem 0;
      }

      .card {
        font-size: 12px;
        .card-body {
          padding: .75rem .5rem;
        }
      }
    }
  }

  &.view-table {
    .distribution-btns {
      width: 80px;
    }
  }

  .table-assortment,
  .table-clients,
  .table-quotes {
    background-color: $body-bg;
    color: $gray-900;
    max-width: 100%;
    margin-bottom: 0;

    td,
    th {
      padding: 2px;
      height: 1px;
      div.td-value-container {
        height: 100%;
      }
    }
  }

  .storage-selector-container {
    min-width: 12rem;
    @media (max-width: 768px) {
      width: 100%;
    }
  }

  table:has(tr.active) {
    tr.assortment-row:not(.active, :has(th)) {
      display: none;
    }

    tr.assortment-row {
      tr.client-row:not(.active, :has(th)) {
        display: none;
      }
    }
  }

  .client-list,
  .quote-list {
    background-color: $main-wrapper-bg;
  }

  .view-mode-toggle {
    position: fixed;
    bottom: 1rem;
    right: 1rem;
    z-index: 100;

    span.icon {
      line-height: 0;

      .fa-square {
        transform: matrix(1.2, 0, 0, 0.6, 0, 0);
        margin: -2px;
      }
    }
  }

}