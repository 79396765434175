@import '../../vendor/sb-admin-2/vendor/bootstrap/scss/functions';
@import '../../vendor/sb-admin-2/vendor/bootstrap/scss/variables';

nav.topbar {
  background-color: theme-color('light');

  .navbar-light {
    .navbar-nav {
      .nav-item {
        .nav-link {
          color: $gray-400;

          &:hover {
            color: $gray-500;
          }

          &:active {
            color: $gray-600;
          }
        }
      }
    }
  }

  .test-mode-banner {
    margin-left: 2rem;
    font-weight: bold;
    font-size: 2rem;
    color: $red;
  }
}
