@import '../../../vendor/sb-admin-2/vendor/bootstrap/scss/functions';
@import '../../../vendor/sb-admin-2/vendor/bootstrap/scss/variables';

.talman {
  font-size: $font-size-sm;
  font-weight: 500;
  padding-bottom: 2rem;

  .pallet-passports {
    width: 100%;
  }
}

.talmans {
  .table-striped {
    tr.selected {
      background-color: #c6d6c9;
    }
  }
}
