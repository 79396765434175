.quotes {
  flex: 1;
  table {
    th {
      &.nds {
        width: 80px;
      }
    }
    td {
      &.nds {
        text-align: center;
      }
    }
  }
}

.copy-quote-button {
  display: inline-block;
  outline: none;
  border-width: 0;
  border-radius: 3px;
  box-sizing: border-box;
  max-width: 100%;
  text-align: center;
  text-decoration: none;
  background: #091e420a;
  cursor: pointer;
  padding: 0 12px;
  vertical-align: middle;
  color: #3a3a3a;
  transition: background 0.1s ease-in-out, color 0.1s ease-in-out;

  &:hover {
    background: #091e4214;
    text-decoration: inherit;
    color: black;
  }
}
