@import '../../vendor/sb-admin-2/vendor/bootstrap/scss/functions';
@import '../../vendor/sb-admin-2/vendor/bootstrap/scss/variables';

.dw-form-tabs {
  color: $gray-900;

  &.nav {
    &.nav-pills {
      padding-bottom: $input-btn-padding-y;

      .nav-link {
        padding: $btn-padding-y-sm $btn-padding-x-sm;
      }
    }
  }

  .nav-item {
    font-size: 0.8rem;

    a.active {
      color: $gray-900;
    }
  }
}
