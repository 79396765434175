@use 'sass:math';
@import '../../vendor/sb-admin-2/vendor/bootstrap/scss/functions';
@import '../../vendor/sb-admin-2/vendor/bootstrap/scss/variables';

$th-padding: 5px;
$th-fas-margin-right: 4px;

.table-no-hover {
  .dw-table {
    table.table-hover {
      tbody tr {
        &:hover {
          color: inherit;
          background-color: inherit;
        }
      }
      tbody tr:nth-child(odd):hover {
        background-color: $table-accent-bg;
      }
    }
  }
}

.dw-table,
.dw-table .table {
  color: $gray-900;
  width: 100%;
  max-height: calc(100vh - $topbar-base-height - $footer-base-height);

  table.table-striped {

    tr.has-changes {
      background-color: $diff-color;
    }

  }

  .grip-padding>tbody>tr>td {
    padding-left: $table-cell-padding-sm !important;
    padding-right: $table-cell-padding-sm !important;
  }

  .card-header {
    padding: 0.25rem 0.75rem;

    input.search {
      width: 125px;
      margin-right: 0.75rem;
      display: inline-block;
      font-size: 0.75rem;
    }

    .header-tabs ul {
      padding-bottom: 0;
    }
  }

  .card-body {
    position: relative;
    width: 100%;
    padding: 0;
    overflow-x: auto;

    .grip-container {
      .grip-lastgrip {
        .grip-resizable {
          width: 4px; // to not have a gap between last column and card-body
        }
      }
    }
  }

  table {
    width: 100%;
    font-size: $font-size-sm;
    border-collapse: collapse;
    border-top: none;
    border-left: none;
    border-right: none;
    margin-bottom: 0;

    tr th {
      border-top: none;
    }

    tr th:first-child,
    tr td:first-child {
      border-left: none;
    }

    tr th:last-child,
    tr td:last-child {
      border-right: none;
    }

    thead {
      position: sticky;
      top: 0;
      background-color: $white;
      z-index: 1;
    }

     // to add bottom border on scroll
    thead:after {
      content: '';
      width: 100%;
      position: absolute;
      left: 0;
      bottom: 2px;
      border-bottom: $table-border-width solid $table-border-color;
    }

    th {
      padding: $th-padding;

      &.id {
        width: 50px;
      }

      &.boolean {
        width: 60px;
      }

      &.date-time,
      &.date,
      &.inn,
      &.kpp,
      &.money,
      &.ogrn {
        width: 100px;
      }

      &.phone {
        width: 120px;
      }

      &.type,
      &.kind,
      &.status,
      &.unit {
        width: 120px;
      }

      &.user {
        width: 250px;
      }

      &.sortable {
        cursor: pointer;
      }

      div.th {
        display: flex;
        flex-direction: row;
        align-items: flex-end;

        .title {
          text-align: left;
          .fas {
            display: inline;
            margin-left: $th-fas-margin-right;
            opacity: 0;
            &.visible {
              opacity: 1;
            }
          }

          flex-grow: 1;
        }

        .actions {
          display: flex;
          align-items: center;
          flex-wrap: wrap-reverse;
          justify-content: flex-end;
          margin-left: $th-fas-margin-right;


          .th-filter-btn {
            width: $font-size-lg;
            height: $font-size-lg;
            font-size: $font-size-sm;
            padding: -2px;
            .filter-remove {
              font-size: $font-size-base;
            }
          }
        }
      }

      .fas {
        cursor: pointer;
      }

      .fa-filter {
        color: grey;

        &.selected {
          color: green;
        }
      }

      .fa-times {
        color: red;
      }
    }

    td {
      padding: $table-cell-padding-sm;
      position: relative;

      &.id {
        text-align: center;
      }

      &.boolean {
        text-align: center;
      }

      &.date {
        text-align: center;
        width: 75px;
      }

      &.date-time {
        text-align: center;
        width: 120px;
      }

      i.icon {
        min-width: 7px;

        &.fa-times {
          cursor: pointer;
        }
      }
    }
  }

  .filter {
    z-index: 99;
    border: 1px solid #777;
    position: fixed;
    background-color: #fff;
    padding: 10px;

    font-size: 0.8rem;
  }

  .dropdown-toggle {
    font-size: 0.8rem;
    padding-right: 10px;
  }

  .columns-selector {
    display: inline-block;

    a {
      padding-left: 0;
      top: 1px;
      position: relative;
    }

    i {
      padding: 0 5px 0 5px;
      display: inline;

      &.hidden {
        width: 25px;
        display: inline-block;
      }
    }

    &.dropdown-menu {
      overflow-y: auto;
      max-height: 300px;
      top: 34px;
      margin: 5px;
      display: none;

      &.show {
        display: block;
      }

      .dropdown-item {
        cursor: pointer;
        padding: 0.25rem 1rem;

        span {
          min-width: 80%;
          display: inline-block;
        }
      }
    }

    .hover {
      border-top: 2px solid #ccc;
    }
  }

  .paging-control {
    position: sticky;
    left: 0;
    bottom: 0;
    width: 100%;
    background-color: $body-bg;
    padding: math.div($grid-gutter-width, 2);
    border-top: $table-border-width solid $table-border-color;
    border-bottom-left-radius: $border-radius;
    border-bottom-right-radius: $border-radius;

    .page-size {
      font-size: 0.8rem;
    }

    select {
      font-size: 0.7rem;
    }

    .pagination {
      &.pagination-sm {
        margin-bottom: 0;
      }
    }
  }

  .react-tooltip {
    color: #222;
    background-color: #f8f9fc;
    max-width: 50%;
    border: solid 1px #ccc;
  }

  .copy-button {
    border-color: $gray-300;
  }

  .table-info {
    position: fixed;
    top: 30%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: $white;
    border: 2px solid $gray-300;
    z-index: 1000;

    h4 {
      margin: 2px;
      vertical-align: middle;
    }

    th {
      background-color: $white;
      border: 1px solid $gray-300;
    }

    td {
      border: 1px solid $gray-300;
    }
  }

  .hover-listener {
    display: inline-block;
  }

  .fit-parent-size {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    padding: $table-cell-padding-sm;
  }
}
