@import 'src/vendor/sb-admin-2/vendor/bootstrap/scss/functions';
@import 'src/vendor/sb-admin-2/vendor/bootstrap/scss/variables';

.route-table {
  color: $gray-900;
  flex: 1;

  .card-header {
    padding: 0.25rem 0.75rem;
  }

  table {
    font-size: 0.75rem;
    th {
      color: $gray-900;
      text-align: center;
    }

    td {
      &.quote-number button {
        font-size: 0.75rem;
      }
    }
  }
}

.card-header {
  padding: 0.25rem 0.75rem !important;

  .form-group {
    margin-bottom: 0;
  }
}
