@import '../../../vendor/sb-admin-2/vendor/bootstrap/scss/functions';
@import '../../../vendor/sb-admin-2/vendor/bootstrap/scss/variables';

.assembly {
  font-size: $font-size-sm;
  font-weight: 500;
  padding-bottom: 2rem;

  &.view-cards {

    table {
      font-size: 16px;

      td,
      th {
        padding: .25rem .25rem .25rem .5rem;
      }

      .assembly-row-btn {
        margin: .5rem 0;
      }

      .card {
        font-size: 12px;
        .card-body {
          padding: .75rem .5rem;
        }
      }
    }
  }

  .line-selector-container {
    min-width: 12rem;
    @media (max-width: 768px) {
      width: 100%;
    }
  }


  table:has(tr.active) {
    tr.assortment-row:not(.active, :has(th)) {
      display: none;
    }
  }

  .assembly-task-list {
    background-color: $main-wrapper-bg;
  }

  .table-assembly,
  .table-assembly-task {
    background-color: $body-bg;
    color: $gray-900;
    max-width: 100%;
    margin-bottom: 0;

    td, th {
      padding: 2px;
      height: 1px;
      div.td-value-container {
        height: 100%;
      }
    }
  }

  .view-mode-toggle {
    position: fixed;
    bottom: 1rem;
    right: 1rem;
    z-index: 100;

    span.icon {
      line-height: 0;

      .fa-square {
        transform: matrix(1.2, 0, 0, 0.6, 0, 0);
        margin: -2px;
      }
    }
  }

}