@import '../../vendor/sb-admin-2/vendor/bootstrap/scss/functions';
@import '../../vendor/sb-admin-2/vendor/bootstrap/scss/variables';
@import '../../vendor/sb-admin-2/vendor/bootstrap/scss/mixins';
@import '../../vendor/sb-admin-2/vendor/bootstrap/scss/mixins/buttons';

.form .card-header {
  padding: 0.25rem 1.25rem;
}

.dw-form {
  color: $gray-900;

  .nav {
    &.nav-pills {
      padding-bottom: $input-btn-padding-y;

      .nav-link {
        padding: $btn-padding-y-sm $btn-padding-x-sm;
      }
    }
  }

  .nav-item,
  .card {
    font-size: 0.8rem;
    a.active {
      color: $gray-900;
    }
  }

  .form-group {
    position: relative;
    .form-control.static {
      background-color: unset;
      border-color: transparent;
      height: auto;
      &.is-invalid {
        border-color: $form-feedback-invalid-color;
      }
    }
  }

  .invalid-feedback {
    display: block;
    position: absolute;
    text-align: right;
    &.inline {
      position: unset;
      display: inline;
  }
  }

  .react-datepicker-wrapper {
    display: block !important;
  }
  .react-datepicker-popper {
    z-index: 10;
  }

  .ql-editor {
    height: 150px;
    max-height: 150px;
    overflow: auto;
  }

  .form-control[readonly] {
    background-color: $white;
    color: $gray-500;
  }

  .attachments-area {
    .dropzone {
      flex: 1;
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 20px;
      border-radius: $border-radius-sm;
      border: $border-width dashed $custom-upload-area-border-default;
      background-color: $custom-upload-area-default;
      color: $gray-600;
      outline: none;
      transition: border 0.24s ease-in-out;
      cursor: pointer;
    }

    .dropzone .drop-tip {
      color: theme-color();
      font-size: 14px;
    }

    .dropzone .drop-tip-restricted {
      font-size: 14px;
    }

    .actions i {
      cursor: pointer;
    }

    .spinner-grow {
      width: 12px;
      height: 12px;
    }
  }
}

.leaflet-map-container {
  height: 80vh;
  width: 86vw;
  border: $border-width solid $border-color;

  .leaflet-routing-container-hide {
    display: none;
  }
}