@import '../../vendor/sb-admin-2/vendor/bootstrap/scss/functions';
@import '../../vendor/sb-admin-2/vendor/bootstrap/scss/variables';
@import '../../vendor/sb-admin-2/vendor/bootstrap/scss/mixins';

.rs-container.form-control {
  padding: 0;

  &.multiple {
    height: auto !important;
  }

  &.focus {
    color: $dropdown-link-color;
    background-color: $input-focus-bg;
    border-color: $custom-select-focus-border-color;
    outline: 0;

    @if $enable-shadows {
      @include box-shadow($input-box-shadow, $input-focus-box-shadow);
    } @else {
      box-shadow: $input-focus-box-shadow;
    }
  }

  .menu {
    width: max-content;
    min-width: 100%;
    max-width: 300px;
    background-color: $body-bg;
    border-radius: $input-border-radius-sm;
    box-shadow: 0 0 0 $border-width $border-color, 0 4px 11px hsla(0, 25%, 44%, 0.1);
    margin-bottom: 8px;
    margin-top: 8px;
    box-sizing: border-box;
    display: flex;
    align-items: stretch;
    overflow: hidden;
    z-index: 100;

    .rs__menu-list {
      display: flex;
      flex-direction: column;
      flex-grow: 1;

      .rs__option {
        padding: $dropdown-item-padding-y $dropdown-item-padding-x;
        font-weight: $font-weight-normal;
        color: $dropdown-link-color;
        text-decoration: if($link-decoration ==none, null, none);
        white-space: nowrap; // prevent links from randomly breaking onto new lines
        background-color: transparent; // For `<button>`s
        border: 0; // For `<button>`s
        text-wrap: balance;
        cursor: pointer;

        @include hover-focus() {
          color: $dropdown-link-hover-color;
          text-decoration: none;
          @include gradient-bg($dropdown-link-hover-bg);
        }

        &.rs__option--is-selected {
          color: $dropdown-link-active-color;
          text-decoration: none;
          @include gradient-bg($dropdown-link-active-bg);
        }
        .sub-label {
          padding-left: 0.75rem;
        }
      }
    }
  }

  .single-value.rs__single-value {
    opacity: 1;
    color: $gray-900;
    transition: opacity 300ms;

    &.disabled {
      color: $dropdown-link-disabled-color;
    }
  }

  .control.rs__control {
    size: $input-font-size-sm;
    border-radius: $input-border-radius-sm;
    min-height: auto;
    padding: $input-padding-y-sm $input-padding-x-sm;
    cursor: text;
  }

  .rs__control--is-disabled {
    .indicators-container {
      color: $dropdown-link-disabled-color;
    }
  }

  // .value-container {
  // }

  .indicators-container {
    cursor: pointer;
  }

  .no-options-message,
  .loading-message {
    padding: $dropdown-item-padding-y $dropdown-item-padding-x;
  }

  &.error-border {
    border-color: $danger;
  }
}
