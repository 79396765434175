@import '../../vendor/sb-admin-2/vendor/bootstrap/scss/functions';
@import '../../vendor/sb-admin-2/vendor/bootstrap/scss/variables';

.brand-logo {
  display: flex;
  align-items: flex-start;
  padding: $nav-link-padding-y $nav-link-padding-x;

  .sidebar-brand-logo {
    object-fit: fill;
    max-width: 100%;
    max-height: 3rem;
    display: block;
    transition: filter 0.3s ease, transform 0.3s ease;
  }

  &:hover .sidebar-brand-logo {
    filter: brightness(110%);
    transform: scale(1.02);
  }
}

.login-logo {
  display: flex;
  align-items: center;
  justify-content: center;

  .login-brand-logo {
    padding: 10px 10px;
    object-fit: fill;
    max-width: 90%;
    max-height: 100%;
    display: block;
    transition: filter 0.3s ease, transform 0.3s ease;
  }

  &:hover .login-brand-logo {
    filter: brightness(100%);
    transform: scale(1.02);
  }
}
