@import 'src/vendor/sb-admin-2/vendor/bootstrap/scss/functions';
@import 'src/vendor/sb-admin-2/vendor/bootstrap/scss/variables';

.card-header {
  padding: 0.25rem 0.75rem !important;

  .run-info {
    .btn {
      height: fit-content;
    }
    .form-group {
      margin-bottom: 0;
    }
  }
}

.summary-row,
.summary-row:hover {
  color: $black !important;
  font-weight: bold !important;
  background-color: $white !important;
}