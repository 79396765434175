@import 'vendor/sb-admin-2/vendor/bootstrap/scss/functions';
@import 'vendor/sb-admin-2/vendor/bootstrap/scss/variables';

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

#root .input-group-sm > .form-control,
#root .input-group-sm > .custom-select,
#root .input-group-sm > .input-group-prepend > .input-group-text,
#root .input-group-sm > .input-group-append > .input-group-text,
#root .input-group-sm > .input-group-prepend > .btn,
#root .input-group-sm > .input-group-append > .btn {
  padding: 0.25rem 0.4rem;
  font-size: 0.85rem;
  line-height: 1.3;
  border-radius: 0.2rem;
}

#wrapper {
  .main-area {
    margin: 0;
    flex: 1;
    color: $gray-900;

    @media (max-width: 768px) {
      padding: 0;
    }
  }

  footer.sticky-footer {
    padding: 0.5rem 0;
    height: $footer-base-height; // value used in table max height calculation
  }
}

form,
form .form-control,
form .form-control-sm {
  font-size: 0.75rem;
  color: $gray-900;
}

.custom-control.custom-checkbox {
  z-index: 0 !important;
  display: flex;
  align-items: center;
  height: 100%;
}

.alert {
  font-size: 0.75rem;
  margin: 0.5rem;
}