@import 'src/vendor/sb-admin-2/vendor/bootstrap/scss/functions';
@import 'src/vendor/sb-admin-2/vendor/bootstrap/scss/variables';

$editable-cell-min-width: 70px;

.editable-dw-table,
.dw-tabled.editable-dw-table {

  table,
  table.grip-resizable,
  table.grip-padding {
    font-size: 0.75rem;
    overflow-y: auto;

    tr {

      td {

        &.td-editable {
          padding: 0 !important;
          overflow: visible;

          .form-group {
            margin: 0;

            .form-control {

              &.rs-container {
                padding-left: 0;
                padding-right: 0;

                .control.rs__control {
                  padding-left: $table-cell-padding-sm;
                  padding-right: $table-cell-padding-sm;
                }
              }
            }
          }

          .custom-control.custom-checkbox {
            justify-content: center;
          }
        }

        &.id {
          min-width: 10px;
          width: 10px;
          text-align: center;
        }

        &.type {
          min-width: $editable-cell-min-width;
          width: 30px;
        }

        &.boolean {
          min-width: $editable-cell-min-width;
          width: 60px;
        }

        &.assortment,
        &.address {
          min-width: $editable-cell-min-width;
          width: 200px;
        }

        &.money,
        &.number,
        &.date,
        &.time,
        &.type {
          min-width: $editable-cell-min-width;
          width: 80px;
        }

        &.client,
        &.option {
          min-width: $editable-cell-min-width;
          width: 120px;
        }

        &.text {
          min-width: $editable-cell-min-width;
          width: 120px;
          overflow: auto;
          word-break: break-word;
        }

        &.date {
          min-width: $editable-cell-min-width;
          width: 75px;
        }

        &.date-time {
          min-width: $editable-cell-min-width;
          width: 120px;
        }

      }
    }
  }

  .react-datepicker-popper {
    width: max-content;
  }

  .react-datepicker__time-list-item {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .invalid-feedback {
    position: sticky;
    left: 0;
    bottom: 0;
    padding: 6px 10px;
  }
}