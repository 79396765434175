.inactive-link {
  color: grey;
  cursor: not-allowed;
  position: relative;

  &::before {
    content: 'Нет совпадений';
    position: absolute;
    bottom: 120%;
    left: 50%;
    transform: translateX(-50%);
    background-color: #5b5b5b;
    color: #fff;
    padding: 4px 6px;
    border-radius: 8px;
    font-size: 0.8rem;
    visibility: hidden;
    opacity: 0;
    transition: visibility 0s, opacity 0.3s linear;
    z-index: 1;
    white-space: nowrap;
  }

  &:hover::before {
    visibility: visible;
    opacity: 1;
  }
}
